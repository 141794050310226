import { useConfigStore, useNotifyStore, useUserStore } from '~/store'

export default defineNuxtRouteMiddleware(async (to) => { // (to, from)
    const userStore = useUserStore()
    const currentUser = userStore.currentUser
    // console.log('currentUser', currentUser)

    if (!currentUser || (typeof currentUser.k !== 'string' && typeof currentUser.k !== 'number')) {
        const res = await userStore.fetchUser()
        setTimeout(() => {
            if (!res || (res?.data && !res.data?.k)) {
                useConfigStore().setGlobalLoading(false)

                // NOTIFY USER
                const notifyStore = useNotifyStore()
                notifyStore.showMessage({
                    title: 'Utilisateur non authentifié',
                    text: `Vous ne pouvez pas accéder à cette page.`,
                    color: 'error',
                    icon: 'mdi-alert-circle',
                    timer: 5,
                })

                if (to.path !== '/') {
                    setTimeout(() => {
                        return navigateTo('/')
                    }, 50)
                }
            }
        }, 200)
    }
})
